import React, { useCallback, useMemo } from "react";
import { DA_Tooltip } from "@danishagro/shared/src/components/atoms/Tooltip/Tooltip.component";
import { DA_Product } from "@danishagro/shared/src/interfaces/product.interface";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import Skeleton from "react-loading-skeleton";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { useDynamicOverlay } from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import { DA_SalesPriceBase } from "@danishagro/shared/src/interfaces/price.interface";
import { DiscountTableMobile } from "@templates/ProductDetails/components/DiscountTableMobile/DiscountTableMobile.component"; // Adjust the import path as needed
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import S from "./ProductListPrice.module.scss";

export interface DA_ProductListPriceProps {
    price: DA_Product["price"];
    quantity: number;
    discount?: DA_SalesPriceBase;
    hasVariants?: boolean;
    onlyPrice?: boolean;
    onClick?: () => void;
}

export const DA_ProductListPrice = ({
    price,
    quantity,
    discount,
    hasVariants,
    onlyPrice,
    onClick,
}: DA_ProductListPriceProps) => {
    const { currentCulture } = useAppData();
    const { getDictionaryString } = useTranslations();
    const { isMobile } = useScreen();
    const { showOverlay } = useDynamicOverlay();

    const hasQuantityDiscounts =
        price?.quantityDiscounts?.some((discount) => discount.quantityFrom > 0) ?? false;

    const hasDiscount = discount?.priceWithDiscount;

    const FromComponent = () => {
        return hasVariants ? (
            <span>{getDictionaryString("from", { uppercaseFirst: true })} </span>
        ) : null;
    };

    const discountTable = useMemo(() => {
        if (hasQuantityDiscounts && price?.quantityDiscounts) {
            return price.quantityDiscounts
                .filter((discount) => {
                    // Exclude invalid ranges and expired discounts
                    const now = new Date();
                    const discountEndDate = new Date(discount.endDate);
                    const isExpired = discountEndDate.getTime() <= now.getTime();
                    return discount.quantityFrom !== 0 && discount.quantityTo !== 0 && !isExpired;
                })
                .map((discount) => ({
                    quantityFrom: discount.quantityFrom,
                    quantityTo: discount.quantityTo || Infinity,
                    price: discount.price,
                }));
        }
        return [];
    }, [hasQuantityDiscounts, price?.quantityDiscounts]);

    const discountAmountTitle = getDictionaryString("discount.discountAmountTitle");

    const openDynamicDiscountOverlay = useCallback(() => {
        showOverlay({
            id: "discount_amount_open",
            title: discountAmountTitle,
            content: <DiscountTableMobile discountTable={discountTable} />,
        });
    }, [showOverlay, discountTable, discountAmountTitle]);

    const calculatedPrice = useMemo(() => {
        if (price && Number.isFinite(price.priceWithoutVat)) {
            if (discountTable.length > 0) {
                const applicableDiscount = discountTable.find(
                    (item) => quantity >= item.quantityFrom && quantity <= item.quantityTo
                );
                return applicableDiscount ? applicableDiscount.price : price.priceWithoutVat;
            }
            return price.priceWithoutVat;
        }
        return undefined;
    }, [price, discountTable, quantity]);

    const priceElement = (
        <div className={cn(S.currentPrice, onlyPrice && S.onlyPrice)}>
            {Number.isFinite(calculatedPrice) ? (
                <>
                    {hasVariants && <FromComponent />}

                    {formatNumber(calculatedPrice, currentCulture, {
                        decimals: 2,
                    })}
                    <meta itemProp="price" content={calculatedPrice.toString()} />
                    <meta itemProp="priceCurrency" content={price?.currency || "DKK"} />
                </>
            ) : (
                <Skeleton width={70} />
            )}
        </div>
    );

    const renderDiscountPrice = () => (
        <div className={cn(S.currentPrice, onlyPrice && S.onlyPrice)}>
            <div>
                <FromComponent />
                {formatNumber(discount.priceWithDiscount, currentCulture, {
                    decimals: 2,
                })}
                <meta itemProp="price" content={discount.priceWithDiscount.toString()} />
                <meta itemProp="priceCurrency" content={price.currency} />
            </div>
            <div className={S.discountedPrice}>
                {formatNumber(price.priceWithoutVat, currentCulture, {
                    decimals: 2,
                })}
            </div>
        </div>
    );

    const priceElementWithInteraction =
        hasQuantityDiscounts || hasDiscount ? (
            isMobile ? (
                <div
                    role="button"
                    tabIndex={0}
                    onClick={openDynamicDiscountOverlay}
                    onKeyPress={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                            openDynamicDiscountOverlay();
                        }
                    }}
                    className={S.priceClickable}
                >
                    {hasDiscount ? renderDiscountPrice() : priceElement}
                </div>
            ) : discountTable.length > 1 ? ( // Only show DA_Tooltip if there are multiple discounts
                <DA_Tooltip
                    text={
                        <div className={S.discountTable}>
                            {discountTable.map((item, index) => (
                                <div className={S.discountTableItem} key={index}>
                                    <span className={S.amount}>{`${
                                        item.quantityFrom
                                    } ${getDictionaryString("pcs")}`}</span>
                                    <span className={S.pricePerPcs}>
                                        {getDictionaryString("discount.pricePerPcs")}
                                    </span>
                                    <span className={S.price}>
                                        {formatNumber(item.price, currentCulture, {
                                            decimals: 2,
                                        })}
                                    </span>
                                </div>
                            ))}
                        </div>
                    }
                    icon={DA_IconNames.Info}
                    placement="top"
                >
                    {hasDiscount ? renderDiscountPrice() : priceElement}
                </DA_Tooltip>
            ) : hasDiscount ? (
                renderDiscountPrice()
            ) : (
                priceElement
            )
        ) : (
            priceElement
        );

    return (
        <div
            className={S.priceWrapper}
            itemProp="offers"
            itemScope
            itemType="https://schema.org/Offer"
        >
            <div className={S.price}>{priceElementWithInteraction}</div>

            <div className={S.pushContent}>
                <DA_ButtonLink
                    title={getDictionaryString("freight is added")}
                    className={S.shippingLink}
                    onClick={onClick}
                />
            </div>
        </div>
    );
};
