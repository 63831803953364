import React, { useState, useEffect } from "react";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import S from "./DiscountTable.module.scss";

interface DiscountRow {
    quantityFrom: number;
    quantityTo?: number;
    price: number;
}

interface DiscountTableProps {
    discountTable: DiscountRow[];
    currentQuantity: number;
}

export const DiscountTable = ({ discountTable, currentQuantity }: DiscountTableProps) => {
    const { getDictionaryString } = useTranslations();
    const { currentCulture } = useAppData();
    const [isExpanded, setIsExpanded] = useState(false); // Track whether table is expanded

    // Determine the active row
    const getActiveRowIndex = () => {
        return discountTable.findIndex(
            (item) =>
                currentQuantity >= item.quantityFrom &&
                currentQuantity <= (item.quantityTo || Infinity)
        );
    };

    const activeRowIndex = getActiveRowIndex();

    // Automatically expand if the active row index exceeds the first 3 rows
    useEffect(() => {
        if (activeRowIndex > 2) {
            setIsExpanded(true);
        }
    }, [activeRowIndex]);

    // Determine which rows to display
    const rowsToShow = isExpanded ? discountTable : discountTable.slice(0, 3);

    return (
        <div className={S.tableContainer}>
            <table className={S.discountTable}>
                <thead>
                    <tr>
                        <th className={S.titleLeft}>
                            {getDictionaryString("amountDiscount.amountDiscount")}
                        </th>
                        <th className={S.titleRight}>
                            {getDictionaryString("amountDiscount.pricePerPcs")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {rowsToShow.map((item, index) => (
                        <tr key={index} className={index === activeRowIndex ? S.activeRow : ""}>
                            <td className={S.firstColumn}>
                                {item.quantityFrom} {getDictionaryString("pcs")}
                            </td>
                            <td className={cn(S.alignRight, S.secondColumn)}>
                                {formatNumber(item.price, currentCulture, {
                                    decimals: 2,
                                })}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Show button if table is not expanded */}
            {!isExpanded && discountTable.length > 3 && (
                <DA_ButtonLink
                    title={getDictionaryString("amountDiscount.showMore")}
                    className={S.expandButton}
                    onClick={() => setIsExpanded(true)}
                    icon={DA_IconNames.ChevronSmallDown}
                />
            )}
        </div>
    );
};
