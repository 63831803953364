import { useState, useEffect, useCallback } from "react";

function useCountdown(endDate: string) {
    const calculateTimeLeft = useCallback(() => {
        const now = new Date();
        const end = new Date(endDate);
        const difference = end.getTime() - now.getTime();

        if (difference <= 0) {
            return { days: 0, hours: 0, minutes: 0 };
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);

        return { days, hours, minutes };
    }, [endDate]);

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

    useEffect(() => {
        setTimeLeft(calculateTimeLeft()); // Ensure correct state on mount

        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 60000); // Update every minute

        return () => clearInterval(timer); // Cleanup timer on unmount
    }, [calculateTimeLeft]);

    return {
        timeLeft,
    };
}

export default useCountdown;
