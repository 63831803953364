import React, { useEffect } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { DA_ProductPrice } from "@danishagro/shared/src/interfaces/price.interface";
import Skeleton from "react-loading-skeleton";
import S from "./Price.module.scss";

export interface DA_PriceProps {
    priceLabel: string;
    price: DA_ProductPrice;
    discountedPrice?: number;
    comment?: string;
    hasVariants?: boolean;
    largeSpacing?: boolean;
    alternative?: boolean;
    isQuickOrder?: boolean;
}

export const DA_Price = ({
    priceLabel,
    price,
    discountedPrice,
    comment,
    hasVariants,
    largeSpacing,
    alternative,
    isQuickOrder,
}: DA_PriceProps) => {
    const { currentCulture } = useAppData();

    const [isDiscount, setIsDiscount] = React.useState(false);

    useEffect(() => {
        setIsDiscount(price.discountPrice > 0);
    }, [price.discountPrice]);

    return (
        <div
            className={cn(S.container, S.alternativeContainer)}
            itemProp="offers"
            itemScope
            itemType="https://schema.org/Offer"
        >
            {!alternative && <div className={S.priceLabel}>{priceLabel}</div>}
            <div
                className={cn(
                    S.price,
                    largeSpacing && S.largeSpacing,
                    isDiscount && S.displayAsColumn
                )}
            >
                {isDiscount && (
                    <div className={S.discountedPrice}>
                        {formatNumber(price.priceWithoutVat, currentCulture, {
                            decimals: 2,
                        })}
                    </div>
                )}
                <div className={cn(S.currentPrice, isQuickOrder && S.quickOrderPrice)}>
                    {hasVariants && <span className={S.label}>Fra </span>}
                    {price && price.priceWithoutVat !== undefined ? (
                        <>
                            {formatNumber(
                                price.discountPrice > 0
                                    ? price.discountPrice
                                    : price.priceWithoutVat,
                                currentCulture,
                                { decimals: 2 }
                            )}
                            <meta itemProp="price" content={price.toString()} />
                            <meta itemProp="priceCurrency" content={price.currency} />
                        </>
                    ) : (
                        <Skeleton width={80} />
                    )}
                </div>
            </div>

            {alternative && (
                <div className={S.alternativePriceLabel}>{`${priceLabel} ${
                    comment && comment
                }`}</div>
            )}

            {!alternative && comment && <span className={S.comment}>{comment}</span>}
        </div>
    );
};
