import { useCallback, useEffect, useState } from "react";
import { useAppData } from "../../../../../contexts/appData.context";
import {
    DA_NavigationPage,
    DA_NavigationPageSimple,
} from "../../../../../interfaces/navigationPage.interface";

export const useWebshop = (pageTree: DA_NavigationPage[] | DA_NavigationPageSimple[]) => {
    const { currentSite } = useAppData();

    const [webshopNavigation, setWebshopNavigation] = useState<DA_NavigationPage[]>([]);

    const mapNavigationPageUrls = useCallback(
        (pageArray: DA_NavigationPage[]): DA_NavigationPage[] => {
            const pages = [];

            (pageArray || []).map((item) => {
                const tmpPage: DA_NavigationPage = {
                    ...item,
                    path: `${item.path}`.replace(/^\/+/, "/"),
                    children: [],
                };

                if (item.children && item.children.length) {
                    tmpPage.children.push(...mapNavigationPageUrls(item.children));
                }

                pages.push(tmpPage);
            });

            return pages;
        },
        []
    );

    // Init

    useEffect(() => {
        if (currentSite !== "B2B") return;

        if (pageTree) {
            // Mapping data to absolute urls
            const pages = mapNavigationPageUrls(pageTree as DA_NavigationPage[]);
            setWebshopNavigation(pages);
        }
    }, [mapNavigationPageUrls, currentSite, pageTree]);

    return {
        webshopNavigation,
    };
};
