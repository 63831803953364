import React from "react";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import S from "./DiscountTableMobile.module.scss";

interface DiscountRow {
    quantityFrom: number;
    quantityTo?: number;
    price: number;
}

interface DiscountTableProps {
    discountTable: DiscountRow[];
}

export const DiscountTableMobile = ({ discountTable }: DiscountTableProps) => {
    const { getDictionaryString } = useTranslations();
    const { currentCulture } = useAppData();
    return (
        <div className={S.discountTable}>
            {discountTable.map((item, index) => (
                <div className={S.discountTableItem} key={index}>
                    <span className={S.amount}>{`${item.quantityFrom} ${getDictionaryString(
                        "pcs"
                    )}`}</span>
                    <span className={S.pricePerPcs}>
                        {getDictionaryString("discount.pricePerPcs")}
                    </span>
                    <span className={S.price}>
                        {formatNumber(item.price, currentCulture, {
                            decimals: 2,
                        })}
                    </span>
                </div>
            ))}
        </div>
    );
};
