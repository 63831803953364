import React, { useEffect, useState } from "react";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import useCountdown from "@templates/ProductDetails/components/hooks/useCountdown.hook";
import Skeleton from "react-loading-skeleton";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import S from "./Countdown.module.scss";

export interface CountdownProps {
    endDate: string;
}

export const DA_Countdown = (props: CountdownProps) => {
    const { getDictionaryString } = useTranslations();
    const [isLoading, setIsLoading] = useState(true);
    const { timeLeft } = useCountdown(props.endDate);

    useEffect(() => {
        // Hide the Skeleton after the first calculation is complete
        if (
            timeLeft.days !== undefined &&
            timeLeft.hours !== undefined &&
            timeLeft.minutes !== undefined
        ) {
            setIsLoading(false);
        }
    }, [timeLeft]);

    return (
        <>
            {isLoading ? (
                <Skeleton width={100} height={20} />
            ) : (
                <div className={S.countdown}>
                    <DA_Icon name={DA_IconNames.Clock} className={S.icon} />
                    <div className={S.expiresIn}>
                        {getDictionaryString("productsExpireIn")}
                        <span className={S.currentCountdown}>
                            {timeLeft.days >= 0 && (
                                <span>
                                    {getDictionaryString("productsExpireDays", {
                                        days: timeLeft.days,
                                    })}
                                </span>
                            )}
                            {timeLeft.hours >= 0 && (
                                <span>
                                    {getDictionaryString("productsExpireHours", {
                                        hours: timeLeft.hours,
                                    })}
                                </span>
                            )}
                            {timeLeft.minutes >= 0 && (
                                <span>
                                    {getDictionaryString("productsExpireMinutes", {
                                        minutes: timeLeft.minutes,
                                    })}
                                </span>
                            )}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};
